import { template as template_740cc1be67524cf38a7e2225201205fc } from "@ember/template-compiler";
const WelcomeHeader = template_740cc1be67524cf38a7e2225201205fc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
