import { template as template_ab2e2e766ec040979302d2d7f9aa95ed } from "@ember/template-compiler";
const FKLabel = template_ab2e2e766ec040979302d2d7f9aa95ed(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
