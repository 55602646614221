import { template as template_121c308f68864e4f8d29f05b2ffcfd34 } from "@ember/template-compiler";
const FKText = template_121c308f68864e4f8d29f05b2ffcfd34(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
